import { fetchProductCategories } from 'lib/models/productCategories';
import { useCallback, useEffect, useMemo, useState } from 'react';

function useProductCategories() {
	const [loading, setLoading] = useState<boolean>(false);

	const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchProductCategories();
		setProductCategories(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const productCategoriesMap = useMemo(() => {
		return productCategories.reduce((all, productCategory) => {
			return {
				...all,
				[`${productCategory._id}`]: productCategory,
			};
		}, {} as { [key in string]: ProductCategory });
	}, [productCategories]);

	return { loading, productCategories, productCategoriesMap, refetch };
}

export default useProductCategories;
