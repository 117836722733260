import common from './common';
import system from './system';
import users from './users';
import vehicles from './vehicles';
import access from './access';
import merch from './merch';
import product from './product';

const es = {
	common,
	system,
	users,
	vehicles,
	access,
	merch,
	product,
} as const;

export default es;
