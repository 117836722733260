const common = {
	// Common
	username: 'Nombre de usuario',
	password: 'Contraseña',
	firstName: 'Nombre',
	lastName: 'Apellido',
	email: 'Email',
	accept: 'Aceptar',
	cancel: 'Cancelar',
	close: 'Cerrar',
	create: 'Crear',
	delete: 'Eliminar',
	edit: 'Editar',
	details: 'Detalles',
	filter: 'Filtrar',
	add: 'Agregar',
	general: 'General',
	go: 'Ir',
	open: 'Abrir',
	reload: 'Recargar',
	save: 'Guardar',
	search: 'Buscar',
	watch: 'Ver',
	selected: 'seleccionados',
	upload: 'Subir',
	success: 'Se guardó correctamente',
	download: 'Descargar',
	densePadding: 'Fila densa',
	rowsPerPage: 'Líneas por página',
	findUser: 'Busque un usuario ...',
	import: 'Importar',
	importingPleaseWait: 'Por favor espere, importando',
	more: 'Más',
	code: 'Código',
	loading: 'Cargando, por favor espere...',
	role: 'rol',
	role_admin: 'Administrador',
	role_panel: 'Usuario de panel',
	role_parkingAdmin: 'Administrator "estacionamiento"',
	role_parkingCashier: 'Cajero "estacionamiento"',
	role_parkingGate: 'Estacionamiento "puerta"',
	role_user: 'Cliente',
	role_barAdmin: 'Administrador "bar"',
	role_barPanel: 'Editor "bar"',
	role_barCashier: 'Cajero "bar"',
	published: 'Publicado',
	active: 'Activo',
	inactive: 'Inactivo',
	draft: 'Borrador',
	canceled: 'Cancelado',
	selectFile: 'Seleccionar archivo',
	fileError: 'Formato de archivo no soportado',
	importSuccess: 'Archivo importado correctamente',
	language: 'Idioma',
	spanish: 'español',
	english: 'inglés',
	valueTo: 'Hasta',
	$lte: 'Menor o igual a',
	$gte: 'Mayor o igual a',
	backOfficeDocumentationTitle: 'Documentación del panel',
	backOfficeDocumentationDescription: 'Link a documentación sobre el uso del panel',
	itDocumentationTitle: 'Documentación IT',
	itDocumentationDescription: 'Link a documentación sobre la configuración del servidor',
	welcome:
		'Bienvenido {{name}}, si necesitás ayuda con el uso del panel, o con alguna configuración del servidor, puedes acceder a la documentación con los links de abajo.',
	welcomeClient: 'Bienvenido {{name}}',
	export: 'Exportar',
	tokenExpired: 'Tu sesión ha expirado, por favor ingrese nuevamente su usuario y contraseña',
	serverIsDown: 'El servidor se encuentra inactivo, por favor contacte al departamento de sistemas para más ayuda',
	followingElementsWithError:
		'Los siguientes elementos con la columna "{{key}}" ({{elements}}) no pudieron ser creados, error: {{error}}',
	status: 'Estado',
	deleteTitle: 'Eliminación',
	deleteText: '¿Está seguro que desea eliminar el elemento seleccionado?',
	cancelDescription: '¿Está seguro que desea regresar a la pantalla de login?',
	exampleFile: 'Descargar un archivo .csv de ejemplo',
	account: 'cuenta',
	account_plural: 'cuentas',
	dashboard: 'escritorio',
	favorite: 'favorito',
	favorite_plural: 'favoritos',
	inbox: 'buzón de entrada',
	personal_area: 'Perfil',
	profile: 'perfil',
	user: 'usuario',
	user_plural: 'usuarios',
	panel_user_plural: 'Usuarios (panel)',
	app_user_plural: 'Usuarios (App)',
	user_me: 'mis datos',
	userTitle: 'datos de {{name}}',
	resetPassword: 'Blanquear contraseña',
	resetPasswordTitle: 'Blanquear contraseña',
	resetPasswordText: '¿Desea blanquear la contraseña del usuario seleccionado?',
	creationPasswordText:
		'La primera vez que el usuario inicie sesión creará su contraseña la cual será utilizada en los acesos siguientes.',
	client: 'Cliente',
	client_plural: 'Clientes',
	loginDate: 'Último ingreso',
	loadError: 'Hubo un problema al intentar cargar los datos',
	clientTitle: 'Clientes',
	identifier: 'Identificador',
	companyName: 'Nombre de la compañía',
	city: 'Ciudad',
	address: 'Dirección',
	phone: 'Teléfono',
	phoneNumber: 'Numero de Telefono',
	alternativeEmail: 'Email alternativo',
	associationLabel: 'Asociación de Marca',
	position: 'Position',
	area: 'Area',
	actions: 'Acciones',
	data: 'Datos',
	contact: 'Contacto',
	mode: 'Modo',
	date: 'Fecha',
	range: 'Rango',
	from: 'Desde',
	to: 'Hasta',
	to2: 'al',
	website: 'Sitio web',
	users: 'Usuarios',
	crm: 'CRM',
	bill: 'factura',
	bill_plural: 'facturas',
	person: 'persona',
	person_plural: 'personas',
	document: 'documento',
	document_plural: 'documentos',
	main: 'principal',
	documentation: 'documentación',
	dates: 'Fechas',
	contacts: 'Contactos',
	remove: 'remover',
	total: 'Total',
	action: 'Acción',
	transfer: 'Transferencia',
	cash: 'Efectivo',
	pending: 'Pendiente',
	accepted: 'Aceptado',
	rejected: 'Rechazado',
	place: 'Lugar',
	place_plural: 'Lugares',
	createPlace: 'Crear lugar',
	name: 'Nombre',
	opening: 'Apertura',
	closes: 'Cierre',
	barClosure: 'Cierre de barras',
	doorClosure: 'Cierre de puertas',
	mapsLink: 'Enlace Mapa',
	createEvent: 'Crear event',
	createArtist: 'Create artist',
	eventTitle: 'Editar evento ({{title}})',
	artistTitle: 'Edit artist ({{title}})',
	currentPlaceNeeded: 'Primero debes crear un "lugar"',
	ticketsLink: 'Link a tiketera',
	tiktokEmbedded: 'Embebido de Tiktok',
	featured: 'Destacado',
	isFeatured: 'Es destacado',
	app_event_plural: 'Eventos',
	deletePlaceWithEvents: 'No se pueden eliminar lugares con eventos',
	featuredPosition: 'Posición destacada',
	setFeatured: 'Destacar',
	unsetFeatured: 'Quitar',
	tag: 'Tag',
	title: 'Título',
	featured_plural: 'Destacados',
	all: 'Eventos',
	up: 'Subir',
	down: 'Bajar',
	image: 'Imagen',
	uploadingPleaseWait: 'Subiendo, por favor espere.',
	uploadingError: 'El archivo no pudo ser subido.',
	lineup: 'Lineup',
	deleteWithLikesWarning: 'El elemento seleccionado posee LIKES, ¿Está seguro que desea continuar?',
	artist_plural: 'Artistas',
	artist: 'Artista',
	genre: 'Género',
	genre_plural: 'Géneros',
	instagramEmbedded: 'Embebido de instagram',
	parking_vehicles: 'Tipo de vehículos',
	parking_gate: 'Estacionamiento',
	parking_cashier: 'Est. Cobros',
	vehicle_code: 'Código',
	description: 'Descripción',
	priceHour: 'Precio por hora',
	priceHalfStay: 'Precio por media estadía',
	priceStay: 'Precio por estadía',
	continue: 'Continuar',
	confirm: 'Confirmar',
	any: 'Cualquiera',
	faq: 'Preg. frecuentes',
	appConfig: 'Configuración',
	conditionsUrl: 'URL Condiciones',
	policyUrl: 'URL política',
	privacyUrl: 'URL privacidad',
	deletionUrl: 'URL de eliminación',
	mapsLinkLabel:
		'Puedes copiar la URL del iframe de Google Maps o copiar todo el código HTML. El sistema recuperará automáticamente la URL y, si el código es correcto, el iframe aparecerá automáticamente.',
	instagramLinkLabel:
		'Puedes copiar la URL del iframe de Instagram o copiar todo el código HTML. El sistema recuperará automáticamente la URL. Puedes comprobar la validez del link accediendo al botón "Comprobar Link".',
	checkLink: 'Comprobar Link',
	question: 'Pregunta',
	response: 'Respuesta',
	withoutElements: 'No hay elementos existentes, para crear uno nuevo por favor presionar el botón "Crear"',
	createFaq: 'Crear pregunta frecuente',
	editFaq: 'Editar pregunta frecuente',
	publishTitle: 'Publicar evento',
	publishDescription: '¿Está seguro que desea publicar el evento seleccionado?',
	publishError: 'El evento seleccionado no pudo ser publicado',
	unpublishTitle: 'Despublicar evento',
	unpublishDescription: '¿Está seguro que desea despublicar el evento seleccionado?',
	unpublishError: 'El evento seleccionado no pudo ser despublicar',
	publish: 'Publicar',
	unpublish: 'Despublicar',
	view: 'Ver',
	featuredDraftError:
		'Los eventos destacados sólo pueden ser eventos publicados, para agregar un evento a destacados primero debes publicarlo.',
	notifications: 'Notificaciones',
	createNotification: 'Crear notificación',
	artistNewDates: 'Artistas',
	cancelation: 'Cancelaciones',
	custom: 'Personalizadas',
	eventChanges: 'Eventos',
	allValues: 'Todo',
	topic: 'Tema',
	cancelEventError: 'El evento no pudo ser cancelado',
	cancelEventTitle: 'Cancelar evento',
	cancelEventDescription: '¿Estás seguro que deseas cancelar el evento seleccionado?',
	selectArtist: 'Seleccionar artista',
	QRCodes: 'Códigos QR',
	category: 'Categoría',
	categories: 'Categorías',
	important: 'Importante',
	sendNotification: 'Enviar Notificacion',
	msjNotification: 'Sólo se enviarán notificaciones cuando cancele un evento o publique un evento modificado.',
	msjNotificationEspecial:
		'Sólo se enviará una notificación si se modifico el título, se reprogramo la fecha o se actualizo el lineup.',
	msjNotificationConfirm: 'La notificacion solo se enviara si hubo una modificacion en el evento.',
	of: 'de',
	price: 'Precio',
	promotionPrice: 'Precio (promo)',
	merch: 'Mercancía',
	product: 'Producto',
	bar: 'Barra',
	yes: 'Si',
	no: 'No',
} as const;

export default common;
