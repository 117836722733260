import { FetchProductProps, fetchProducts } from 'lib/models/products';
import { useCallback, useEffect, useState } from 'react';

function useProducts(props: FetchProductProps) {
	const [loading, setLoading] = useState<boolean>(false);

	const [products, setProducts] = useState<Product[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchProducts(props);
		setProducts(response);
		setLoading(false);
	}, [props]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, products, refetch };
}

export default useProducts;
