import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	LinearProgress,
	ListItemText,
	TableCell,
	TableHead,
	TableRow,
	useTheme,
	Typography,
	useMediaQuery,
} from '@mui/material';

import { largeRowsPerPageOptions } from 'config/constants';

import { enqueueSnackbarError } from 'lib/helpers';
import { deleteProduct } from 'lib/models/products';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import ConfirmationDialog from 'components/ConfirmationDialog';
import ResponsiveTable from 'components/ResponsiveTable';

import Actions from './Actions';
import { price } from 'lib/numbers';

type Props = {
	data: Product[];
	productCategoriesMap: { [key in string]: ProductCategory };
	refetch: () => void;
	loading: boolean;
	page: number;
	rowsPerPage: number;
	handleChangePage: (_: unknown, newPage: number) => void;
	handleChangeRowsPerPage: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onEdit: (product: Product) => void;
};

export default function ProductsList({
	data,
	productCategoriesMap,
	refetch,
	loading,
	page,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	onEdit,
}: Props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<Product | null>(null);

	const onDelete = (product: Product) => {
		setShowDeleteConfirmation(product);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			console.warn('Deleting!');
			const deleted = await deleteProduct(showDeleteConfirmation);
			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	return (
		<Box>
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}

			{!!loading && <LinearProgress />}

			<ResponsiveTable
				elements={isMobile ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				listSx={{ maxHeight: '70vh' }}
				tableSx={{ maxHeight: '65vh' }}
				list={{
					primaryKey: '_id',
					renderListItemText: (product) => {
						return (
							<ListItemText
								primary={
									<Box>
										<Typography>{product.title}</Typography>
										<Typography>{product.price}</Typography>
										<Typography>
											<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
												{product.categories?.map((pCat) => {
													return (
														<Box>
															<Typography variant="caption">
																{productCategoriesMap[pCat]?.title ?? ''}{' '}
																{productCategoriesMap[pCat]?.description ? (
																	<strong>({productCategoriesMap[pCat]?.description})</strong>
																) : (
																	''
																)}
															</Typography>
														</Box>
													);
												})}
											</Box>
										</Typography>
									</Box>
								}
								secondary={<Actions product={product} onEdit={onEdit} onDelete={onDelete} />}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:title')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:price')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:promotionPrice')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:categories')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (product) => {
						return (
							<TableRow tabIndex={-1} key={product._id}>
								<TableCell>{product.title}</TableCell>
								<TableCell>{price(product.price ?? 0)}</TableCell>
								<TableCell>{price(product.promotionPrice ?? 0)}</TableCell>
								<TableCell>
									<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
										{product.categories?.map((pCat) => {
											return (
												<Box>
													<Typography variant="caption">
														{productCategoriesMap[pCat]?.title ?? ''}{' '}
														{productCategoriesMap[pCat]?.description ? (
															<strong>({productCategoriesMap[pCat]?.description})</strong>
														) : (
															''
														)}
													</Typography>
												</Box>
											);
										})}
									</Box>
								</TableCell>
								<TableCell>
									<Actions product={product} onEdit={onEdit} onDelete={onDelete} />
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: data.length,
						page,
						rowsPerPage,
						rowsPerPageOptions: largeRowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</Box>
	);
}
