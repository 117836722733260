import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, Save } from '@mui/icons-material';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	LinearProgress,
	FormGroup,
	TextField,
	Button,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Box,
	Checkbox,
} from '@mui/material';

import { enqueueSnackbarError } from 'lib/helpers';
import { createProduct, updateProduct } from 'lib/models/products';

import useProductCategories from 'hooks/useProductCategories';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

type Props = {
	defaultValue: Product;
	onClose: () => void;
};

export default function ProductModal({ defaultValue, onClose }: Props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [product, setProduct] = useState<Product>(defaultValue);

	const categories = product.categories || [];

	const { productCategories } = useProductCategories();

	const successSnackbar = useSuccessSnackbar();

	const canSave = !!product.price && !!product.title && product.categories.length > 0;

	const onUpdate = (key: keyof Product) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setProduct({ ...product, [key]: value });
		};
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps

	const save = async () => {
		setLoading(true);
		try {
			await (product._id ? updateProduct(product) : createProduct(product));
			setLoading(false);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		onClose();
		setLoading(false);
	};

	return (
		<Dialog open onClose={onClose} fullWidth>
			{loading && <LinearProgress />}
			<DialogTitle>{t('common:product')}</DialogTitle>
			<DialogContent>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{product && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={product.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
							required={true}
						/>
						<TextField
							id="price"
							type="number"
							label={t('common:price')}
							fullWidth={true}
							value={product.price}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('price')}
							disabled={loading}
						/>
						<TextField
							id="promotionPrice"
							type="number"
							label={t('common:promotionPrice')}
							fullWidth={true}
							value={product.promotionPrice}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('promotionPrice')}
							disabled={loading}
						/>

						<Typography variant="h6">Categorías</Typography>

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Título
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{productCategories.map((productCategory) => {
									return (
										<TableRow key={`product-category-table-${productCategory}`}>
											<TableCell align="left" padding="normal">
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>
													{productCategory.title}
													<Typography variant="caption">{productCategory.description}</Typography>
												</Box>
											</TableCell>
											<TableCell align="left" padding="normal">
												<Checkbox
													onChange={() => {
														setProduct((current) => {
															return {
																...current,
																categories: categories.includes(`${productCategory._id}`)
																	? categories.filter((el) => el !== `${productCategory._id}`)
																	: [...categories, `${productCategory._id}`],
															};
														});
													}}
													checked={categories.includes(`${productCategory._id}`)}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!canSave}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}
