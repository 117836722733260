import { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	LinearProgress,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import useProductCategories from 'hooks/usePopulatedProductCategories';

import { enqueueSnackbarError } from 'lib/helpers';
import { createSection, updateSection } from 'lib/models/sections';

type Props = {
	defaultValue: PopulatedProductSection;
	onClose: () => void;
	refetch: () => void;
};

export default function SectionModal({ defaultValue, onClose }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [loading, setLoading] = useState(false);
	const [section, setSection] = useState(defaultValue);
	const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

	const { productCategories, loading: loadingCategories } = useProductCategories();

	const onUpdate = (key: keyof ProductSection) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setSection({ ...section, [key]: value });
		};
	};

	const handleDragStart = (index: number) => {
		setDraggingIndex(index);
	};

	const handleDragOver = (event: DragEvent<HTMLTableRowElement>, index: number) => {
		event.preventDefault(); // Prevent the default behavior (like animation)

		if (draggingIndex === index || draggingIndex === null) return;

		const updatedCategories = [...section.categories];
		const draggedItem = updatedCategories[draggingIndex];

		// Remove dragged item and insert it at the new index
		updatedCategories.splice(draggingIndex, 1);
		updatedCategories.splice(index, 0, draggedItem);

		setDraggingIndex(index);
		setSection((current) => ({ ...current, categories: updatedCategories }));
	};

	const handleDrop = () => {
		setDraggingIndex(null); // Clear dragging state
	};

	const save = async () => {
		setLoading(true);
		try {
			await (section?._id ? updateSection(section) : createSection(section));
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		onClose();
	};

	return (
		<Dialog open>
			{loading && <LinearProgress />}
			<DialogTitle>{t('product:sections')}</DialogTitle>
			<DialogContent sx={{ minWidth: '30vw' }}>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{section && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={section.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
						/>
						<Select
							labelId="status"
							id="status"
							label={t('product:allowsPromotionPrice')}
							fullWidth={true}
							value={section.allowsPromotionPrice ? 'y' : 'n'}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={(ev) => {
								setSection({ ...section, allowsPromotionPrice: ev.target.value === 'y' });
							}}
							disabled={loading}
						>
							<MenuItem value="y">{t('product:allowPromotionPrice')}</MenuItem>
							<MenuItem value="n">{t('product:dontAllowPromotionPrice')}</MenuItem>
						</Select>

						<Typography variant="h6">Categorías seleccionadas</Typography>

						{loadingCategories && <CircularProgress />}

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Título
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{section.categories.map((productCategory, index) => {
									return (
										<TableRow
											key={`section-category-table-${productCategory._id}`}
											onDragStart={() => handleDragStart(index)}
											onDragOver={(ev) => handleDragOver(ev, index)}
											draggable={true}
											onDrop={handleDrop}
											style={{
												backgroundColor: draggingIndex === index ? '#f0f0f0' : 'transparent',
												cursor: 'move',
											}}
										>
											<TableCell align="left" padding="normal">
												<Box sx={{ display: 'flex', flexDirection: 'column' }}>
													{productCategory.title}
													<Typography variant="caption">{productCategory.description}</Typography>
												</Box>
											</TableCell>
											<TableCell align="left" padding="normal">
												<Checkbox
													onChange={() => {
														setSection((current) => {
															return {
																...current,
																categories: current.categories.filter(
																	(category) => category._id !== productCategory._id
																),
															};
														});
													}}
													checked={true}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>

						<Typography variant="h6" sx={{ mt: 2 }}>
							Seleccionar categorías
						</Typography>

						{loadingCategories && <CircularProgress />}

						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										Título
									</TableCell>
									<TableCell align="left" padding="normal">
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{productCategories
									.filter((current) => !withCategory(section, current))
									.map((productCategory) => {
										return (
											<TableRow key={`category-table-${productCategory._id}`}>
												<TableCell align="left" padding="normal">
													<Box sx={{ display: 'flex', flexDirection: 'column' }}>
														{productCategory.title}
														<Typography variant="caption">{productCategory.description}</Typography>
													</Box>
												</TableCell>
												<TableCell align="left" padding="normal">
													<Checkbox
														onChange={() => {
															setSection((current) => {
																return {
																	...current,
																	categories: [...current.categories, productCategory],
																};
															});
														}}
														checked={false}
													/>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!section.title}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}

function withCategory(section: PopulatedProductSection, productCategory: PopulatedProductCategory | ProductCategory) {
	return !!section.categories.find((category) => category._id === productCategory._id);
}
