import { API_DOMAIN, EVENT_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';
import { zInternationalization } from 'lib/zodHelpers';
import { z } from 'zod';
import { zPlace } from './places';

export const zLineup = z.object({
	_id: z.optional(z.string()),
	code: z.optional(z.string()),
	title: z.string(),
	time: z.optional(z.string()),
	position: z.optional(z.number()),
	//artist: z.optional(z.string()),
	artist: z.optional(z.array(z.string())),
});

export const appEventStatus: AppEventStatus[] = ['active', 'canceled', 'draft'];

const eventBase = {
	_id: z.optional(z.string()),
	title: zInternationalization,
	description: zInternationalization,
	date: z.string(),
	lineup: z.optional(z.array(zLineup)),
	featuredPosition: z.optional(z.number().min(1)),
	instagramEmbedded: z.optional(z.string()),
	tiktokEmbedded: z.optional(z.string()),
	ticketsLink: z.optional(z.string()),
	image: z.optional(z.string()),
	imageSquare: z.optional(z.string()),
	imageVertical: z.optional(z.string()),
	tag: z.optional(z.string()),
	genre: z.optional(z.string()),
	likes: z.optional(z.number()),
	status: z.custom<AppEventStatus>(isAppEventStatus),
	doorClosure: z.optional(z.string()),
	barClosure: z.optional(z.string()),
	opening: z.optional(z.string()),
	closes: z.optional(z.string()),
};

export const zEvent = z.object({
	...eventBase,
	place: z.string(),
});

export const zPopulatedEvent = z.object({
	...eventBase,
	place: zPlace,
});

const zEventArrayResponse = z.object({
	total: z.number(),
	// We only need to validate the first element, if its ok, we assume that the rest are ok
	elements: z.custom<PopulatedAppEvent[]>((data) => {
		if (Array.isArray(data) && data.length === 0) {
			const parsed = zPopulatedEvent.safeParse(data);
			if (!parsed.success) {
				console.error(`${parsed.error}`);
			}
			return parsed.success;
		}
		if (!Array.isArray(data)) {
			console.error(`response should be a valid array`);
			return false;
		}
		return true;
	}),
});

export type FetchEventSearchProps = {
	search?: string | null;
	place?: string;
	status: AppEventStatus;
};

export type FetchEventProps = FetchEventSearchProps & PaginationProps;

export async function fetchEvents(props: FetchEventProps): Promise<PaginateSource<PopulatedAppEvent>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'date']: props.order || 'asc',
		};

		const { filter, options } = getSearch(sort, props);

		const response = await api.get(`${API_DOMAIN}${EVENT_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zEventArrayResponse.parse(response.data);

		return data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchEvent(id: string): Promise<PopulatedAppEvent | null> {
	try {
		const response = await api.get(`${API_DOMAIN}${EVENT_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		return data.success ? data.data : null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateLineup(eventId: string, lineup: Lineup): Promise<PopulatedAppEvent | null> {
	if (!lineup._id) {
		delete lineup._id;
	}

	const url = lineup._id
		? `${API_DOMAIN}${EVENT_URL}/${eventId}/lineup/${lineup._id}`
		: `${API_DOMAIN}${EVENT_URL}/${eventId}/lineup`;

	const response = await (lineup._id ? api.patch(url, lineup) : api.post(url, lineup));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	const data = zPopulatedEvent.safeParse(response.data);

	if (data.success) {
		return data.data;
	}

	console.error(data.error);

	return null;
}

export async function updateEvent(
	event: PopulatedAppEvent,
	sendNotification?: boolean
): Promise<PopulatedAppEvent | null> {
	try {
		const url = event._id ? `${API_DOMAIN}${EVENT_URL}/${event._id}` : `${API_DOMAIN}${EVENT_URL}`;

		if (!event._id) {
			delete event._id;
		}

		let postData = event?.place?._id
			? { ...event, place: event.place._id, sendNotification }
			: { ...event, sendNotification };

		const response = await (event._id ? api.patch(url, postData) : api.post(url, postData));

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return null;
}

export async function publishOrUnPublish(
	eventId: string,
	type: 'publish' | 'unpublish',
	sendNotification?: boolean
): Promise<boolean> {
	try {
		const url = `${API_DOMAIN}${EVENT_URL}/${eventId}/${type}`;
		const requestBody = sendNotification !== undefined ? { sendNotification } : {};

		const response = await api.patch(url, requestBody);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return true;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return false;
}

export async function cancelEvent(eventId: string, sendNotification?: boolean): Promise<boolean> {
	try {
		const url = `${API_DOMAIN}${EVENT_URL}/${eventId}/cancel`;
		const requestBody = sendNotification !== undefined ? { sendNotification } : {};

		const response = await api.patch(url, requestBody);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return true;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return false;
}

export async function updateImage(
	id: string,
	image: File,
	mode: 'square' | 'wide' | 'vertical'
): Promise<PopulatedAppEvent | null> {
	try {
		const formData = new FormData();
		formData.append('file', image);
		const url = `${API_DOMAIN}${EVENT_URL}/${id}/image/${mode}`;
		const response = await api.post<PopulatedAppEvent>(url, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return data.data;
		}
	} catch (error) {
		console.error(error);
	}
	return null;
}

export async function updatePosition(
	event: PopulatedAppEvent,
	mode: 'increase' | 'decrease'
): Promise<PopulatedAppEvent | null> {
	try {
		const url = `${API_DOMAIN}${EVENT_URL}/${event._id}/position/${mode}`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return null;
}

export async function addOrRemovePosition(
	event: PopulatedAppEvent,
	type: 'add' | 'remove'
): Promise<PopulatedAppEvent | null> {
	try {
		const url = `${API_DOMAIN}${EVENT_URL}/${event._id}/${type}/position`;

		const response = await api.patch(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		const data = zPopulatedEvent.safeParse(response.data);

		if (data.success) {
			return data.data;
		}

		throw new Error('invalid response');
	} catch (error) {
		console.error(error);
	}

	return null;
}

export async function deleteEvent(id: string): Promise<boolean> {
	try {
		const response = await api.delete(`${API_DOMAIN}${EVENT_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response?.data?._id === id;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function getLatestFeaturedOrder() {
	try {
		const response = await api.get<{ current: number; next: number }>(`${API_DOMAIN}${EVENT_URL}/featuredOrder/latest`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (
			!response.data ||
			typeof response.data !== 'object' ||
			isNaN(response.data.current) ||
			isNaN(response.data.next)
		) {
			throw new Error('invalid response');
		}

		return response.data.next;
	} catch (error) {
		console.error(error);
		return 1;
	}
}
export function isFetchEventProps(data: any): data is FetchEventProps {
	return !!data && !isNaN(data.page) && !isNaN(data.rowsPerPage) && (!data.search || typeof data.search === 'string');
}

export function isAppEventStatus(status: any): status is AppEventStatus {
	return typeof status === 'string' && appEventStatus.includes(status as AppEventStatus);
}
