import React from 'react';

import { validateComponent } from 'lib/session';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

type Props = AuthorizedComponentProps;

const AuthorizedComponent: React.FunctionComponent<Props> = ({
	element: Component,
	mode,
	children,
	validator,
	...rest
}) => {
	const { logged, rules, user } = useLoggedAndRules();
	// If user is logged:
	if (logged && user) {
		// If a custom validator exists, use it:
		if (validator) {
			if (validator(user.type, rules)) {
				return Component ? <Component {...rest} /> : children ? <>{children}</> : null;
			}
		}
		// If not use generic validations:
		else if (validateComponent(rules, { ...rest }, mode)) {
			return Component ? <Component {...rest} /> : children ? <>{children}</> : null;
		}
	}
	return null;
};

export default AuthorizedComponent;
