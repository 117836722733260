import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from 'tss-react/mui';
import {
	useTheme,
	ListItemText,
	useMediaQuery,
	TableHead,
	TableRow,
	TableCell,
	Button,
	CircularProgress,
	Box,
} from '@mui/material';

import { Face as Avatar } from '@mui/icons-material';

import { sanitizedInput } from 'lib/helpers';
import { inviteUsers } from 'lib/models/users';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

import { rowsPerPageOptions } from 'config/constants';
import { UsersTypes } from 'types/global';
import { useNavigate } from 'react-router';
import moment from 'moment';
import useLoggedUser from 'hooks/useLoggedUser';
import useIsAppUsers from 'hooks/useIsAppUsers';

type Props = {
	loading?: boolean;
	editUrl?: string;
	users: User[];
	paper?: boolean;
	disabled?: boolean;
	refetch?: () => {};
	customAction?: CustomTooltipProps;
};

// Array of supported user types:
const supportedTypes = {
	// If logged user type is 'admin', it can list all users
	[UsersTypes.admin]: '*',
	// If logged user type is parking admin (only show 'parking users')
	[UsersTypes.parkingAdmin]: [UsersTypes.parkingCashier, UsersTypes.parkingGate] as UserType[],
	// If logged user type is bar admin (only show 'bar users')
	[UsersTypes.barAdmin]: [UsersTypes.barCashier] as UserType[],
};

export default function UserTable(props: Props) {
	const { t } = useTranslation();

	const { classes } = useStyles();

	const navigate = useNavigate();

	const isAppUsers = useIsAppUsers();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [search, setSearch] = useState('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchValue = sanitizedInput(search);

	const logged = useLoggedUser();

	const users = props.users.filter((user) => {
		// Filter by search value:
		const searchFilter =
			!searchValue ||
			sanitizedInput(user.first_name).includes(searchValue) ||
			sanitizedInput(user.last_name).includes(searchValue);
		// Do not show the super admin user (can't be deleted or edited)
		const adminFilter = user.username !== 'admin';
		// Do not show logged user (Logged user is edited from the profile screen)
		const loggedFilter = user._id !== logged?._id;
		// Filter by user type
		const loggedType = logged?.type as keyof typeof supportedTypes | undefined;
		// Validate supported types
		const rolesFilter =
			!!loggedType && (supportedTypes[loggedType] === '*' || supportedTypes[loggedType]?.includes(user.type));
		// Return filter result
		return searchFilter && adminFilter && loggedFilter && rolesFilter;
	});

	function onEdit(selected: User) {
		const { _id } = selected;
		navigate(`/panel/${props.editUrl || 'users'}/${_id}`);
	}

	return (
		<FullLayout paper={props.paper}>
			<SearchToolbar
				onSearch={(value) => setSearch(value)}
				live={true}
				disabled={props.disabled}
				customAction={props.customAction}
			/>

			{!props.disabled && (
				<ResponsiveTable
					elements={isMobile ? users : users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
					list={{
						primaryKey: '_id',
						avatar: <Avatar />,
						renderListItemText: (user) => {
							return (
								<ListItemText
									onClick={() => onEdit(user)}
									primary={`${user.first_name || ''} ${user.last_name || ''} ${
										isPanelUser(user) ? `(${user.username})` : ''
									}`}
									secondary={
										<Box>
											<address>
												<a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
													{user.email}
												</a>
											</address>
										</Box>
									}
								/>
							);
						},
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										{t('common:username')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:firstName')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:lastName')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:role')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:email')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:status')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:loginDate')}
									</TableCell>
									{!isAppUsers && (
										<TableCell align="left" padding="normal">
											{t('common:actions')}
										</TableCell>
									)}
								</TableRow>
							</TableHead>
						),
						renderRow: (user) => {
							return (
								<TableRow tabIndex={-1} key={user._id}>
									<TableCell>{user.username}</TableCell>
									<TableCell>{user.first_name}</TableCell>
									<TableCell>{user.last_name}</TableCell>
									<TableCell>{t(`common:role_${user.type}`)}</TableCell>
									<TableCell>
										<address>
											<a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
												{user.email}
											</a>
										</address>
									</TableCell>
									<TableCell>
										{user.type !== UsersTypes.admin && user.type !== UsersTypes.panel && (
											<UserStatus user={user} refetch={props.refetch} />
										)}
										{(user.type === UsersTypes.admin || user.type === UsersTypes.panel) && (
											<span className={classes.status}>{t(`common:${user.status}`)}</span>
										)}
									</TableCell>
									<TableCell>
										{user.last_login ? moment(user.last_login).format('DD/MM/YYYY HH:mm:ss') : '- - -'}
									</TableCell>
									{!isAppUsers && (
										<TableCell>
											<Box>
												<Button onClick={() => onEdit(user)} variant="outlined">
													{t(`common:edit`)}
												</Button>
											</Box>
										</TableCell>
									)}
								</TableRow>
							);
						},
						pagination: props.disabled
							? null
							: {
									count: users.length,
									page,
									rowsPerPage,
									rowsPerPageOptions,
									onPageChange: handleChangePage,
									onRowsPerPageChange: handleChangeRowsPerPage,
							  },
					}}
				/>
			)}
		</FullLayout>
	);
}

type UserStatusProps = {
	user: User;
	refetch?: () => any;
};

function UserStatus({ user, refetch }: UserStatusProps) {
	const { t } = useTranslation();
	const { classes } = useStyles();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const send = useCallback(async () => {
		try {
			setLoading(true);
			await inviteUsers(user);
			setLoading(false);
			setError(false);
		} catch (error) {
			setError(true);
		}
		setLoading(false);
		refetch?.();
	}, [refetch, user]);

	if (loading) {
		return <CircularProgress />;
	}
	if (user.status === 'inactive' || user.status === 'invited') {
		return (
			<Box>
				{user.status === 'invited' && <span className={classes.status}>{t(`common:${user.status}`)}</span>}
				<Button onClick={send} variant="outlined">
					{t(`common:update_${error ? 'again' : user.status}`)}
				</Button>
			</Box>
		);
	}
	return <span className={classes.status}>{t(`common:${user.status}`)}</span>;
}

const useStyles = makeStyles()((theme) => ({
	status: {
		marginRight: theme.spacing(1),
	},
}));

function isPanelUser(user: User): boolean {
	return user.type === UsersTypes.admin || user.type === UsersTypes.panel;
}
