import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormGroup,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { enqueueSnackbarError } from 'lib/helpers';
import { createProductCategory, updateProductCategory } from 'lib/models/productCategories';
import { deleteProduct } from 'lib/models/products';

import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	defaultValue: PopulatedProductCategory;
	onClose: () => void;
	refetch: () => void;
};

export default function ProductCategoryModal({ defaultValue, onClose, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [loading, setLoading] = useState(false);
	const [productCategory, setProductCategory] = useState(defaultValue);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<Product | null>(null);

	const onUpdate = (key: keyof ProductCategory) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setProductCategory({ ...productCategory, [key]: value });
		};
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			const deleted = await deleteProduct(showDeleteConfirmation);
			if (!deleted) enqueueSnackbarError(t('merch:deletionError'));
			else {
				const newItems = defaultValue.items.filter((items) => items._id !== showDeleteConfirmation._id);
				setProductCategory({ ...productCategory, items: newItems });
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};

	const onDelete = (product: Product) => {
		setShowDeleteConfirmation(product);
	};

	const save = async () => {
		setLoading(true);
		try {
			await (productCategory?._id ? updateProductCategory(productCategory) : createProductCategory(productCategory));
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		onClose();
	};

	return (
		<Dialog open>
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			{loading && <LinearProgress />}
			<DialogTitle>{t('merch:merchandising')}</DialogTitle>
			<DialogContent sx={{ minWidth: '30vw' }}>
				{loading && <LinearProgress sx={{ mb: 3 }} />}
				{productCategory && (
					<FormGroup sx={{ paddingTop: 1 }}>
						<TextField
							id="title"
							label={t('common:title')}
							fullWidth={true}
							value={productCategory.title}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('title')}
							disabled={loading}
						/>
						<TextField
							id="title"
							multiline={true}
							label={t('common:description')}
							fullWidth={true}
							value={productCategory.description}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={onUpdate('description')}
							disabled={loading}
						/>
						{defaultValue.items.length > 0 && (
							<List
								sx={{
									width: '100%',
									maxWidth: 360,
									bgcolor: 'background.paper',
									position: 'relative',
									overflow: 'auto',
									maxHeight: 300,
									paddingTop: 0,
								}}
							>
								<ListSubheader>{t('common:merch')}</ListSubheader>
								{productCategory.items.map((item) => (
									<ListItem
										key={item._id}
										secondaryAction={
											<IconButton onClick={() => onDelete(item)} edge="end" aria-label="delete">
												<DeleteIcon />
											</IconButton>
										}
									>
										<ListItemText primary={item.title} />
									</ListItem>
								))}
							</List>
						)}

						<Button
							variant="contained"
							color="primary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Save />}
							onClick={save}
							disabled={!productCategory.title}
						>
							{t('common:save')}
						</Button>

						<Button
							variant="contained"
							color="secondary"
							size="large"
							sx={{ m: 1 }}
							startIcon={<Close />}
							onClick={onClose}
						>
							{t('common:cancel')}
						</Button>
					</FormGroup>
				)}
			</DialogContent>
		</Dialog>
	);
}
