import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Delete, Edit } from '@mui/icons-material';
import { Box, LinearProgress, Typography } from '@mui/material';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import { enqueueSnackbarError } from 'lib/helpers';

import ConfirmationDialog from 'components/ConfirmationDialog';
import PaperContainer from 'components/containers/PaperContainer';
import { deleteSection } from 'lib/models/sections';
import { price } from 'lib/numbers';

type Props = {
	loading: boolean;
	data: PopulatedProductSection[];
	onEdit: (section: PopulatedProductSection) => void;
	refetch: () => void;
};

export default function SectionsList({ loading, data, onEdit, refetch }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<PopulatedProductSection | null>(null);

	const onDelete = (section: PopulatedProductSection) => {
		setShowDeleteConfirmation(section);
	};

	const onDeleteHandler = async (mustDelete: boolean) => {
		if (mustDelete && showDeleteConfirmation?._id) {
			if (showDeleteConfirmation.categories.length > 0) {
				setShowDeleteConfirmation(null);

				return enqueueSnackbarError(t('product:errorHasItems'));
			}
			const deleted = await deleteSection(showDeleteConfirmation);

			if (!deleted) {
				enqueueSnackbarError(t('product:deletionError'));
			} else {
				successSnackbar();
				refetch();
			}
		}

		setShowDeleteConfirmation(null);
	};
	return (
		<Box>
			{loading && <LinearProgress sx={{ mb: 3 }} />}
			{showDeleteConfirmation && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteHandler}
				/>
			)}
			<Box>
				{data.length === 0 && <Typography>{t('common:withoutElements')}</Typography>}
				{data.map((el) => {
					return (
						<PaperContainer
							key={el._id}
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								mb: 2,
								position: 'relative',
							}}
						>
							<Box>
								<Typography sx={{ mb: 2 }} variant="h6">
									<strong>{el.title.toUpperCase()}</strong>
								</Typography>
								{el.categories.map((category) => {
									return (
										<Box>
											<Typography
												sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff2cc', padding: 1, mb: 1 }}
											>
												<strong>{category.title}</strong>
											</Typography>

											{category.items.map((item) => {
												return (
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'row',
															justifyContent: 'space-between',
															gap: 8,
															minWidth: '500px',
														}}
													>
														<Typography sx={{ mb: 2 }}>{item.title}</Typography>
														<Typography sx={{ mb: 2 }}>
															<strong>{price(item.price ?? 0)}</strong>
														</Typography>
													</Box>
												);
											})}
										</Box>
									);
								})}
							</Box>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'flex-end',
									gap: 1,
									position: 'absolute',
									top: 16,
									right: 16,
								}}
							>
								<Edit sx={{ cursor: 'pointer' }} onClick={() => onEdit(el)} color="primary" />

								<Delete sx={{ cursor: 'pointer' }} onClick={() => onDelete(el)} color="secondary" />
							</Box>
						</PaperContainer>
					);
				})}
			</Box>
		</Box>
	);
}
