import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
	product: Product;
	onEdit: (ev: Product) => any;
	onDelete: (ev: Product) => any;
};

export default function Actions({ product, onEdit, onDelete }: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1 }}>
				<Button onClick={() => onEdit(product)} variant="outlined">
					{t(`common:edit`)}
				</Button>

				<Button onClick={() => onDelete(product)} variant="outlined" color="error">
					{t(`common:delete`)}
				</Button>
			</Box>
		</Box>
	);
}
