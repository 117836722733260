import { useCallback, useEffect, useState } from 'react';
import { fetchPopulatedSections } from 'lib/models/sections';

function usePopulatedSections() {
	const [loading, setLoading] = useState<boolean>(false);

	const [sections, setSections] = useState<PopulatedProductSection[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchPopulatedSections();
		setSections(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, sections, refetch };
}

export default usePopulatedSections;
