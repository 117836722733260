import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import ProductCategoriesList from 'components/ProductCategoriesList';
import ProductCategoryModal from 'components/ProductCategoryModal';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import usePopulatedProductCategories from 'hooks/usePopulatedProductCategories';

export default function ProductCategories() {
	const defaultCategory: PopulatedProductCategory = {
		title: '',
		items: [],
	};
	const { t } = useTranslation();

	const title = t('product:productCategories');

	const { loading, productCategories, refetch } = usePopulatedProductCategories();

	const [showModal, setShowModal] = useState<PopulatedProductCategory | null>(null);

	const onEdit = (productCategory: PopulatedProductCategory) => {
		setShowModal(productCategory);
	};

	const onClose = () => {
		refetch();
		setShowModal(null);
	};

	return (
		<ScreenContainer title={title} goBack={true}>
			<CreateIcon onCreate={() => setShowModal(defaultCategory)} disabled={loading} />
			{showModal && <ProductCategoryModal defaultValue={showModal} onClose={onClose} refetch={refetch} />}
			<Box>
				<ProductCategoriesList onEdit={onEdit} refetch={refetch} data={productCategories} loading={loading} />
			</Box>
		</ScreenContainer>
	);
}
