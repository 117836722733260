import { z } from 'zod';

export const languages = ['es'] as const;

export const defaultLanguage = 'es';

export const zTime = z.object({
	hours: z.number(),
	minutes: z.number(),
});

export const zInternationalization = z.object({
	es: z.string(),
});
