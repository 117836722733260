import { API_DOMAIN, AUTH_URL, ME_URL, USERS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch } from 'lib/helpers';

export async function serverStatus(): Promise<'logged' | 'unauthorized' | 'down'> {
	try {
		const response = await api.get<User>(`${API_DOMAIN}${ME_URL}`);
		if (!checkStatus(response)) {
			if (response.status !== 404) {
				return 'unauthorized';
			}
		}
		return 'logged';
	} catch (error) {
		console.warn(error);
	}
	return 'down';
}

export async function fetchUsers(type?: UserType, isAppUsers?: boolean, props?: PaginationProps): Promise<User[]> {
	try {
		const sort: Sort = {
			[props?.orderBy || 'first_name']: props?.order || 'asc',
		};
		const { options } = props ? getSearch(sort, props) : { options: undefined };
		const params = type ? { type, options } : { options };
		const response = await api.get<User[]>(`${API_DOMAIN}${USERS_URL}${isAppUsers ? '/app/search' : ''}`, { params });
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchUser(username: string): Promise<User | null> {
	try {
		const response = await api.get<User | null>(`${API_DOMAIN}${USERS_URL}/${username}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateUser(user: User): Promise<User> {
	if (!user._id) {
		delete user._id;
	}

	const url = user._id ? `${API_DOMAIN}${USERS_URL}/${user.username}` : `${API_DOMAIN}${USERS_URL}`;

	const response = await (user._id ? api.patch<User>(url, user) : api.post<User>(url, { user }));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || user;
}

export async function inviteUsers(users: User | User[]): Promise<true> {
	const url = `${API_DOMAIN}${AUTH_URL}/access/multiple`;

	const response = await api.post(url, Array.isArray(users) ? users : [users]);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return true;
}

export async function deleteUser(user: User): Promise<User> {
	const url = `${API_DOMAIN}${USERS_URL}/${user.username}`;

	const response = await api.delete<User>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || user;
}

export async function resetPassword(user: User): Promise<User> {
	const url = `${API_DOMAIN}${USERS_URL}/password/${user.username}`;

	const response = await api.delete<User>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || user;
}

export async function fetchUserById(id: string): Promise<User | null> {
	try {
		const response = await api.get<User | null | undefined>(`${API_DOMAIN}${USERS_URL}/id/${id}`);

		return response.data || null;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export function isUser(user: any): user is User {
	return !!user && !!user._id;
}
