import useIsLogged from './useIsLogged';
import useLoggedUser from './useLoggedUser';
import useRules from './useRules';

function useLoggedAndRules() {
	const logged = useIsLogged();
	const user = useLoggedUser();
	const rules = useRules();

	return { logged, rules, user };
}

export default useLoggedAndRules;
