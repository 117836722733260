import { fetchPopulatedProductCategories } from 'lib/models/productCategories';
import { useCallback, useEffect, useState } from 'react';

function usePopulatedProductCategories() {
	const [loading, setLoading] = useState<boolean>(false);

	const [productCategories, setProductCategories] = useState<PopulatedProductCategory[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchPopulatedProductCategories();
		setProductCategories(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, productCategories, refetch };
}

export default usePopulatedProductCategories;
