function isTrue(data: any): data is true {
	return typeof data === 'boolean' && data === true;
}

function isSelf(data: SelfRule): data is SelfRule {
	return (
		!!data &&
		(typeof data === 'string' ||
			(typeof data === 'object' &&
				(typeof data.self === 'string' ||
					(typeof data.self === 'object' && typeof data.self.db === 'string' && typeof data.self.param === 'string'))))
	);
}

type ListTrue = {
	read: true;
	list: true;
};

type ReadTrue = {
	read: true;
};

type ReadSelf = {
	read: SelfRule;
};

type ListSelf = {
	read: SelfRule;
	list: SelfRule;
};

type Create = {
	create: true;
};

type Edit = {
	update: true;
};

type EditSelf = {
	update: SelfRule;
};

type Full = {
	read: true;
	list: true;
	update: true;
	create: true;
};

function read(self: boolean = true) {
	if (self) {
		return (data: ReadSelf): data is ListSelf => {
			return !!data && isSelf(data.read);
		};
	}
	return (data: any): data is ReadTrue => {
		return !!data && isTrue(data.read);
	};
}

function list(self: boolean = true) {
	if (self) {
		return (data: ListSelf): data is ListSelf => {
			return !!data && isSelf(data.list) && isSelf(data.read);
		};
	}
	return (data: any): data is ListTrue => {
		return !!data && isTrue(data.list) && isTrue(data.read);
	};
}

function create() {
	return (data: any): data is Create => {
		return !!data && isTrue(data.create);
	};
}

function edit(self: boolean = true) {
	if (self) {
		return (data: EditSelf): data is EditSelf => {
			return !!data && isSelf(data.update);
		};
	}
	return (data: any): data is Edit => {
		return !!data && isTrue(data.update);
	};
}

function full() {
	return (data: any): data is Full => {
		return !!data && isTrue(data.list) && isTrue(data.read) && isTrue(data.create) && isTrue(data.update);
	};
}

const _rules = {
	// User Profile
	Profile: {
		rules: [
			{
				name: 'Profile',
				validator: read(),
			},
			{
				name: 'Profile',
				validator: edit(),
			},
		],
	},
	UserList: {
		rules: [
			{
				name: 'User',
				validator: list(false),
			},
		],
	},
	UserReadOnly: {
		rules: [
			{
				name: 'User',
				validator: list(),
			},
		],
	},
	UserFull: {
		rules: [
			{
				name: 'User',
				validator: full(),
			},
		],
	},
	UserEdit: {
		rules: [
			{
				name: 'User',
				validator: edit(),
			},
		],
	},
	PlacesFull: {
		rules: [
			{
				name: 'Place',
				validator: full(),
			},
		],
	},
	FaqFull: {
		rules: [
			{
				name: 'Faq',
				validator: full(),
			},
		],
	},
	AppConfigFull: {
		rules: [
			{
				name: 'AppConfig',
				validator: full(),
			},
		],
	},
	NotificationFull: {
		rules: [
			{
				name: 'Notification',
				validator: full(),
			},
		],
	},
	ParkingFull: {
		rules: [
			{
				name: 'Vehicle',
				validator: full(),
			},
			{
				name: 'Access',
				validator: full(),
			},
		],
	},
	ParkingCashier: {
		rules: [
			{
				name: 'Access',
				validator: create(),
			},
		],
	},
	ParkingGate: {
		rules: [
			{
				name: 'Access',
				validator: edit(false),
			},
		],
	},
	PlaceFull: {
		rules: [
			{
				name: 'Place',
				validator: full(),
			},
		],
	},
	PlaceEdit: {
		rules: [
			{
				name: 'Place',
				validator: edit(),
			},
		],
	},
	EventFull: {
		rules: [
			{
				name: 'Event',
				validator: full(),
			},
		],
	},
	ArtistFull: {
		rules: [
			{
				name: 'Artist',
				validator: full(),
			},
		],
	},

	MerchFull: {
		rules: [
			{
				name: 'Merch',
				validator: full(),
			},
			{
				name: 'MerchCategory',
				validator: full(),
			},
		],
	},

	BarFull: {
		rules: [
			{
				name: 'Product',
				validator: full(),
			},
			{
				name: 'ProductCategory',
				validator: full(),
			},
			{
				name: 'Section',
				validator: full(),
			},
		],
	},
};

const rules: RulesDictionary = _rules;

export type RuleKey = keyof typeof _rules | '*';

export type RuleKeys = RuleKey | Array<RuleKey>;

export default rules;
